@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Rufina:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    scroll-behavior: smooth;
}

:root {
    /*--primary-color: #003300;*/
    --primary-color: #3EB6AE;
    /*--secondary-color: #127212;*/
    --secondary-color: #4C638C;
    --tertiary-color: #4C638C;
    --url-background-services: url('https://carbonoffset.asia/wp-content/uploads/2021/06/Rectangle-39-1.png');
}

body {
    background-color: #F5F5F5;
    margin: 0;
}

ol {
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    margin: 0;
    padding-left: 48px;
}

ol>li {
    line-height: 24px;
}

p {
    margin: 0;
}

.react-tel-input .form-control {
    padding-left: 48px !important;
}

section {
    /*padding-bottom: 96px;*/
    padding-top: 96px;
    position: relative;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
    width: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
    border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color);
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

.collapse-team.active {
    min-height: 300px;
    visibility: visible;
    opacity: 1;
    /* transition: height 0.35s ease-out; */
    /* transition: visibility 0s linear 300ms, opacity 100ms;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s; */
}

.collapse-team.inactive {
    height: 0;
    opacity: 0;
    visibility: hidden;
    /* transition: visibility 0s linear 300ms, opacity 100ms;
    transition: height 0.35s ease-out;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s; */
}

.team-member-div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.team-member-foto.active {
    height: auto;
    opacity: 1;
    visibility: visible;
    transition: height 0.35s ease-out;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
}

.team-member-foto.inactive {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: height 0.35s ease-out;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
}

#more-button {
    border-style: none;
    background: none;
    font: 16px Serif;
    color: blue;
    margin: 0 0 10px 0;
}

#grow input:checked {
    color: red;
}

#more-button:hover {
    color: black;
}

#grow {
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 0;
    overflow: hidden;
}

.text-bold {
    font-weight: bold;
}